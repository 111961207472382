import React from "react";
import { useState } from "react";
import { ComposableMap, Geographies, Geography } from "react-simple-maps";
import "./mapChart.css";
import datastore from "../datastore";
import { Marker } from "react-simple-maps";

const geoEurope =
  "https://raw.githubusercontent.com/deldersveld/topojson/master/continents/europe.json";

export default function MapChart({
  defaultSubsidiary,
  selectSubsidiaryCallback,
}) {
  let subs = [];
  const subsidiaries = datastore.subsidiaries;
  subs = subsidiaries.map((elem) => elem);

  const countriesToMap = datastore.countries[0].data.countries[0].text;
  const allowedCountries = [
    "England",
    "Ireland",
    "Austria",
    "Bulgaria",
    "BosniaandHerzegovina",
    "RepublicSrpska",
    "CzechRepublic",
    "Denmark",
    "Estonia",
    "Finland",
    "FaroeIslands",
    "Guernsey",
    "Gibraltar",
    "Greece",
    "Croatia",
    "Hungary",
    "IsleofMan",
    "Iceland",
    "Jersey",
    "Kosovo",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Latvia",
    "Monaco",
    "Moldova",
    "Macedonia",
    "Montenegro",
    "NorthernIreland",
    "JanMayen",
    "Norway",
    "Poland",
    "Portugal",
    "Romania",
    "Scotland",
    "SanMarino",
    "Serbia",
    "Vojvodina",
    "Slovakia",
    "Slovenia",
    "Switzerland",
    "Ukraine",
    "Wales",
  ];

  const showCountries = countriesToMap.split(";").map((item) => item);

  const array3 = allowedCountries.filter(
    (value) => !showCountries.includes(value)
  );

  const getSelectedCountry = (defaultSubsidiary) => {
    if (defaultSubsidiary === "brussels") {
      return ["WalloonRegion", "FlemishRegion"];
    } else if (defaultSubsidiary) {
      return subs.find((elem) => elem.uid === defaultSubsidiary).data.countryid;
    } else {
      return null;
    }
  };

  const [selectedCity, setSelectedCity] = useState(defaultSubsidiary);
  const [selectedCountry, setSelectedCountry] = useState(
    getSelectedCountry(defaultSubsidiary)
  );
  const [selectedColor, setSelectedColor] = useState("#58ff8f");

  return (
    <div className="map-container">
      <ComposableMap
        width={1100}
        height={1000}
        projection="geoAzimuthalEqualArea"
        projectionConfig={{
          rotate: [-5.0, -49, 0],
          scale: 2500,
        }}
      >
        <Geographies geography="/europe.json">
          {({ geographies }) =>
            geographies
              .filter((countries) => {
                const country = countries.properties.geounit;
                const allowedCountries = showCountries;
                return allowedCountries.includes(country);
              })
              .map((geo) => (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  fill="#DDD"
                  stroke="#FFF"
                  strokeWidth={0.9}
                  style={{
                    default: {
                      fill:
                        geo.properties.geounit === selectedCountry[0] ||
                        geo.properties.geounit === selectedCountry[1] ||
                        geo.properties.geounit === selectedCountry
                          ? "#58ff8f"
                          : "#68bcfd",
                      outline: "none",
                    },
                    hover: {
                      fill:
                        geo.properties.geounit === selectedCountry
                          ? selectedColor
                          : "#68bcfd",
                      outline: "none",
                      opacity: 0.9,
                    },
                    pressed: {
                      fill: "#58ff8f",
                      outline: "none",
                    },
                  }}
                />
              ))
          }
        </Geographies>
        <Geographies geography="/europe.json" width={1000}>
          {({ geographies }) =>
            geographies
              .filter((countries) => {
                const country = countries.properties.geounit;

                return array3.includes(country);
              })
              .map((geo) => (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  fill="#DDD"
                  stroke="#EBEBEB"
                  strokeWidth={1}
                  style={{
                    default: {
                      fill:
                        geo.properties.geounit === selectedCountry
                          ? selectedColor
                          : "#FFF",
                      outline: "none",
                    },
                    hover: {
                      fill: "#FFF",
                      outline: "none",
                    },
                    pressed: {
                      outline: "none",
                      fill: "#FFF",
                    },
                  }}
                />
              ))
          }
        </Geographies>
        {datastore.subsidiaries &&
          datastore.subsidiaries.map((country, index) => {
            return (
              <Marker
                key={index}
                coordinates={[
                  country.data.location.longitude
                    ? country.data.location.longitude
                    : null,
                  country.data.location.latitude
                    ? country.data.location.latitude
                    : null,
                ]}
                fill="#01103d"
              >
                <circle
                  r={10}
                  onClick={() => {
                    setSelectedCity(country.uid);
                    setSelectedCountry(
                      country.data.countryid === "Belgium"
                        ? ["WalloonRegion", "FlemishRegion"]
                        : country.data.countryid
                    );
                    setSelectedColor("#58ff8f");
                    selectSubsidiaryCallback &&
                      selectSubsidiaryCallback(country.uid);
                  }}
                  className={
                    selectedCity === country.uid ? "marker-pointed" : "marker"
                  }
                />
              </Marker>
            );
          })}
      </ComposableMap>
    </div>
  );
}
